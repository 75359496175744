import React from 'react';
import PageSection from 'ui-kit/page-section/page-section';
import PageLayout from 'components/layouts/page/page.layout';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CenteredHero } from 'components/centered-text-hero/centered-hero.component';

const NotFound = ({ data }: { data: GatsbyTypes.PageNotFoundDataQuery }) => {
    const { t } = useTranslation();
    const loggedIn = useSelector(accountIsLoggedInSelector);

    return (
        <PageLayout metaData={{ nodeTitle: 'Page Not Found' }}>
            <PageSection className="text-center">
                <CenteredHero
                    image={data.notFoundDesktop}
                    eyebrowText={t('pages.notFound.404')}
                    linkTo={`${loggedIn ? '/secure/profile/personal-info' : '/'}`}
                    linkLabel={loggedIn ? t('pages.notFound.ctas.loggedIn') : t('pages.notFound.ctas.loggedOut')}
                    title={t('pages.notFound.flewTheCoop')}
                    body={t('pages.notFound.cantFindPage')}
                    subtitle=""
                    classNames="centered-hero--full-page"
                    sectionIndex={0}
                    dataGALocation="404-GoHome"
                />
            </PageSection>
        </PageLayout>
    );
};

export default NotFound;

export const query = graphql`
    query PageNotFoundData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        notFoundDesktop: file(relativePath: { eq: "assets/images/not-found-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
